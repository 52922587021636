import React from "react"
import styled from "styled-components"
import Product1 from "../../images/product/product-commerce2x-shopping.png"
import Product2 from "../../images/product/commerce2x-multi-stores-inventory.png"

import Product3 from "../../images/product/commerce2x-Insights-report.png"
import Product4 from "../../images/product/product-bigbarters-ecommerce.png"
import Product5 from "../../images/product/commerce2x-rtl-support.png"
import Product6 from "../../images/product/commerce2x-access-control-level.png"
import Product7 from "../../images/product/commerce2x-pwa.png"
import Product8 from "../../images/product/commerce2x-pos.png"

import Product9 from "../../images/product/commerce2x-seller-price.jpg"
import Arrow from "../../images/product/checkmark.png"

import { Section, Container } from "../global"

const Features = () => (
  <StyledSection>
  
    <StyledContainer>
      
    
      <FeaturesGrid>
 
     
        {/* <FeatureItem>
        <img src={Product2} style={{width:'100%','border-radius':'5px'}}></img>
          <FeatureTitle>Create your online store</FeatureTitle>
          <FeatureText>
          Convert your store into the marketplace where multiple vendors can sell their products. 
             Give power to your vendors/suppliers to sell online and allow consumers different purchase options from multiple vendors to increase conversions on your website.
          </FeatureText>
        </FeatureItem> */}

          <FeatureItem>
       
          <FeatureText>
          <Subtitle>Core features</Subtitle>
      <SectionTitle>The all-in-one platform to scale your commerce business</SectionTitle>
      <SectionSubTitle>Enjoy the flexibility and extensibility with one platform with all the commerce<br/> features for the developers and small businesses.</SectionSubTitle>
      <h3 style={{marginBottom:'15px'}}>
            <img src={Arrow} style={{width:'6%', color:'#1437BF'}}></img> Launch a modern online ecommerce store
            </h3 >
            <h3 style={{marginBottom:'15px'}}>
            <img src={Arrow} style={{width:'6%', color:'#1437BF'}}></img> Convert store into marketplace
            </h3 >
            <h3 style={{marginBottom:'15px'}}>
            <img src={Arrow} style={{width:'6%', color:'#1437BF'}}></img> Point of sale (POS)
            </h3>
            <h3 style={{marginBottom:'15px'}}>
            <img src={Arrow} style={{width:'6%'}}></img> Multi-store inventory management
            </h3>
            <h3 style={{marginBottom:'15px'}}>
            <img src={Arrow} style={{width:'6%', color:'#1437BF'}}></img> Insights report
            </h3>
            <h3 style={{marginBottom:'15px'}}>
            <img src={Arrow} style={{width:'6%', color:'#1437BF'}}></img> Product SEO
            </h3>
            <h3 style={{marginBottom:'15px'}}>
            <img src={Arrow} style={{width:'6%', color:'#1437BF'}}></img> Arabic support
            </h3>
            <h3 style={{marginBottom:'15px'}}>
            <img src={Arrow} style={{width:'6%', color:'#1437BF'}}></img> Access control level
            </h3>
            <h3 style={{marginBottom:'0px'}}>
            <img src={Arrow} style={{width:'6%', color:'#1437BF'}}></img> Progressive Web App (PWA)
            </h3>
            
          </FeatureText>
        </FeatureItem>

        <FeatureItem>
        <img src={Product1} style={{width:'100%', marginBottom:'50px'}}></img>
        <h2 style={{color:'#0F39BF', marginBottom:'10px'}}>Order Management System </h2>
        <h3>Commerce2x cloud based order management system that’s built for your customers and busiest days.</h3>
        
        </FeatureItem>
       
      </FeaturesGrid>
    
    </StyledContainer>
  </StyledSection>
)

export default Features

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.white};
  // clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const StyledContainer = styled(Container)`
  /* transform: skewY(5deg);
  border-radius: 4px;
  background-image: linear-gradient(to top, #fefefe 0%, #fbfbfb 100%); */
  background-color: ${props => props.theme.color.background.white};
`

const SectionTitle = styled.h1`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  text-align: left;
  font-weight:500;
  
  font-size: 3rem;
  line-height: 1.0714285714;
  margin-bottom: 20px;
`
const SectionSubTitle = styled.p`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: left;
  // font-size:24px;
  margin: 0 auto 60px;
  // line-height:30px;
  text-align: left;
`

const Subtitle = styled.h5`
  font-size: 18px;
  color: #1437BF;
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: left;
`

const FeaturesGrid = styled.div`
  // max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom:40px;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  margin-bottom:30px;
  flex-direction: column;
`

const FeatureTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align:center;
  font-weight:500;
`

const FeatureText = styled.p`
  text-align: left;
  
`
