// Theme

const theme = {
  font: {
    primary: `''IBM Plex Sans', sans-serif'`,
    secondary: `''IBM Plex Sans', sans-serif'`,
    light: `''IBM Plex Sans', sans-serif'`,
    normal: `''IBM Plex Sans', sans-serif'`,
    medium: `''IBM Plex Sans', sans-serif'`,
    semibold: `''IBM Plex Sans', sans-serif'`,
    bold: `''IBM Plex Sans', sans-serif'`,
    extrabold: `''IBM Plex Sans', sans-serif'`,
  },
  font_size: {
    xxxsmall: "font-size: 12px; line-height:16px;",
    xxsmall: "font-size: 14px;",
    xsmall: "font-size: 16px;",
    small: "font-size: 17px;",
    regular: "font-size: 22px; line-height: 30px",
    large: "font-size: 28px; line-height: 30px",
    larger: "font-size: 40px; line-height: 50px",
    xlarge: "font-size: 48px; line-height: 60px",
  },

  color: {
    primary: "#071435",
    secondary: "#098c8c",
    accent: "#cca86e",
    background: {
      white: "#ffffff",
      light: "#F9F8F4",
      grey:'#F5F5FC',
      pastal:'#121118'
    },
    white: {
      regular: "#ffffff",
      lessdark: "#faf9f8",
      dark: "#f6f6f6",
      darker: "#eeeeee",
    },
    black: {
      lightest: "#ABA8AF",
      light: "#564F62",
      regular: "#071435",
    },
  },
  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1140px",
  },
}

export default theme
