import React from "react"
import styled from "styled-components"
import Product1 from "../../images/product/laravel.svg"
import Product2 from "../../images/product/commerce2x-vuejs.png"

import Product3 from "../../images/product/commerce2x-elasticsearch.png"
import Product4 from "../../images/product/commerce2x-aws.png"
import Product5 from "../../images/product/commerce2x-nodejs.svg"
import Product6 from "../../images/product/commerce2x-redis.png"

import enterprise from "../../images/product/commerce2x-enterprise.png"
import Support from "../../images/product/commerce2x-support.png"

import { Row, Col } from 'react-grid-system';

import { Section, Container } from "../global"

// const props = {
//   regular: () => ({
//     light: boolean('Light variant (light)', false),
//   }),
//   clickable: () => ({
//     href: text('Href for clickable UI (href)', 'javascript:void(0)'),
//     light: boolean('Light variant (light)', false),
//   }),

// };
// const clickableProps = props.clickable();
const Stack = () => (
  <StyledSection>
  
    <StyledContainer>

    <Row style={{
            paddingTop: '20px'
          }}>
    <Col sm={12} lg={6} >
    <Subtitle>ENTERPRISE SUPPORT</Subtitle>
     
      <SectionTitle>Live support and on-demand training dedicated to your growth</SectionTitle>
      <img src={Support} style={{width:'100%', borderRadius:'5px', marginBottom:'30px'}}/>
      <SectionSubTitle>Commerce2x provides 24/7 live support via chat, phone and email. We also offer continuous trainings through free, on-demand courses tailored to help growing businesses, like yours, grow faster.</SectionSubTitle>
      {/* <img src="https://ariadnegraphql.org/img/open-design.svg" style={{width:'15%',paddingTop:'0px'}}></img> */}
     </Col>

     <Col sm={12} lg={6} >
     <Subtitle>TECH STACK</Subtitle>
      <SectionTitle>Built on a enterprise tech stack and high available on Amazon AWS</SectionTitle>
      <img src={enterprise} style={{width:'100%', height:'52%',borderRadius:'5px', marginBottom:'30px'}}/>
      <SectionSubTitle>The commerce2x APIs allow you build applications and services that can make use of your data and our functionality. Our all storefront functionality is available through our robust API. 
        
      </SectionSubTitle>

    

      
     </Col>
    
    </Row>

    
      
      
       
     
    </StyledContainer>
  </StyledSection>
)

export default Stack

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.pastal};
  // clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const StyledContainer = styled(Container)`
  /* transform: skewY(5deg);
  border-radius: 4px;
  background-image: linear-gradient(to top, #fefefe 0%, #fbfbfb 100%); */
  background-color: ${props => props.theme.color.background.pastal};
`

const SectionTitle = styled.h1`
  color: ${props => props.theme.color.white.regular};
  display: flex;
  justify-content: center;
  margin: 0 auto 20px;
  text-align: left;
  font-weight:400;
`
const SectionSubTitle = styled.p`
  color: ${props => props.theme.color.white.regular};
  display: flex;
  justify-content: left;
  font-size:18px;
  margin: 0 auto 60px;
  line-height:30px;
  text-align: left;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.white.regular};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  // max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:30px;
  flex-direction: column;
`

const FeatureTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align:center;
  font-weight:500;
`

const FeatureText = styled.p`
  text-align: center;
  
`
