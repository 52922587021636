import React from "react"
import styled from "styled-components"
import { Row, Col } from 'react-grid-system';
import { ClickableTile } from 'carbon-components-react';
import { Section, Container } from "../global"
import { Button } from 'carbon-components-react';
import { graphql, useStaticQuery, Link } from "gatsby";
// const props = {
//   regular: () => ({
//     light: boolean('Light variant (light)', false),
//   }),
//   clickable: () => ({
//     href: text('Href for clickable UI (href)', 'javascript:void(0)'),
//     light: boolean('Light variant (light)', false),
//   }),

// };
// const clickableProps = props.clickable();
const StartNow = () => (
  <StyledSection>
  
    <StyledContainer>
      <SectionTitle>Start your business journey with Commerce2x</SectionTitle>
      <SectionSubTitle>Try Commerce2x for free, and explore all the tools and services you need to <br/>start, run, and grow your business.
        
      </SectionSubTitle>

      <SectionSubTitle> <HeaderButton><a href="https://app.commerce2x.com" target="_blank" style={{textDecoration:'none', color:'#fff'}}>Start Free Trial</a></HeaderButton>
        
</SectionSubTitle>
      
     
      
       
     
    </StyledContainer>
  </StyledSection>
)

export default StartNow

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.grey};
  // clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const StyledContainer = styled(Container)`
  /* transform: skewY(5deg);
  border-radius: 4px;
  background-image: linear-gradient(to top, #fefefe 0%, #fbfbfb 100%); */
  background-color: ${props => props.theme.color.background.grey};
`

const SectionTitle = styled.h1`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: left;
  margin: 0 auto 20px;
  text-align: left;
  font-weight:400;
`
const SectionSubTitle = styled.p`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: left;
  font-size:24px;
  // margin: 0 auto 60px;
  line-height:30px;
  text-align: left;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: left;
`

const FeaturesGrid = styled.div`
  // max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:30px;
  flex-direction: column;
`

const FeatureTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align:left;
  font-weight:500;
`

const FeatureText = styled.p`
  text-align: center;
  
`
const HeaderButton = styled.button`
  font-weight: 600;
  font-size: 16px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 0px;
  margin-top:20px;
  text-transform: normal;
  cursor: pointer;
  white-space: nowrap;
  background:#2829D2;
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`