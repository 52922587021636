import React, { Component } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Link } from "gatsby"
import styled from "styled-components"

import Scrollspy from "react-scrollspy"
import {
  // An object of all themes
  themes,
 
  // Direct theme values
  white,
  g10,
  g90,
  g100,
 
  // Specific token values
  interactive01,
  interactive02,
} from '@carbon/themes';
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  ActionsContainer,
} from "./style"
import { Button } from 'carbon-components-react';

import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderMenu,
  HeaderMenuItem,
  HeaderGlobalAction,
  HeaderGlobalBar
} from "carbon-components-react/lib/components/UIShell";
import AppSwitcher20 from "@carbon/icons-react/lib/app-switcher/20";
import { ReactTypeformEmbed } from 'react-typeform-embed';
import logo from "../../../images/product/commerce2x-logo.png";
const NAV_ITEMS = ["Platform", "Features", "Pricing", "Blog", "Contact"]

const TryItButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  color: white;
  letter-spacing: 1px;
  height: 50px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: #131217;
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 1) 0px 2px 10px 0px;
   
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.openForm = this.openForm.bind(this);
  }

  state = {
    mobileMenuOpen: false,
    hasScrolled: false,
  }

  openForm() {
    this.typeformEmbed.typeform.open();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }))
  }

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false })
    }
  }

  getNavAnchorLink = item => (
    <AnchorLink href={`#${item.toLowerCase()}`} onClick={this.closeMobileMenu}>
      {item}
    </AnchorLink>
  )

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  )

  render() {
    const { mobileMenuOpen } = this.state

    return (
      // <Nav {...this.props} scrolled={this.state.hasScrolled}>
      //   <StyledContainer>
      //     <Brand>
      //       <Scrollspy offset={-64} item={["top"]} currentClassName="active">
      //         <AnchorLink href="#top" onClick={this.closeMobileMenu}>
      //           Commerce2x
      //         </AnchorLink>
      //       </Scrollspy>
      //     </Brand>
      //     <Mobile>
      //       <button
      //         onClick={this.toggleMobileMenu}
      //         style={{ color: "black", background: "none" }}
      //       >
      //         {this.state.mobileMenuOpen ? (
      //           <X size={24} alt="close menu" />
      //         ) : (
      //           <Menu size={24} alt="open menu" />
      //         )}
      //       </button>
      //     </Mobile>

      //     <Mobile hide>{this.getNavList({})}</Mobile>
          
      //     <Button kind="secondary">Start Free Trial</Button>
          
      //   </StyledContainer>
      //   <Mobile>
      //     {mobileMenuOpen && (
      //       <MobileMenu>
      //         <Container>{this.getNavList({ mobile: true })}</Container>
      //       </MobileMenu>
      //     )}
      //   </Mobile>
      // </Nav>

      <div className="container">
    <Header aria-label="" class="bx--header" style={{height:'80px', backgroundColor:"#15141B", border:'0px'}}>
      <HeaderName href="#" prefix="">
      <Link to="/" activeStyle={{ color: "white" }} style={{textDecoration:'none', color:'#fff', fontWeight:'700', fontSize:'24px'}}><img src={logo} style={{width:'200px'}}/></Link>
      </HeaderName>
      <HeaderNavigation aria-label="" >
      
      <HeaderMenuItem href="#"  style={{fontWeight:'700', fontSize:'16px'}}><Link to="/platform" activeStyle={{ color: "white" }} style={{textDecoration:'none', color:'#fff'}}>Platform</Link></HeaderMenuItem>
        
         
       
        {/* <HeaderMenuItem href="#">PLATFORM</HeaderMenuItem> */}
        
        {/* <HeaderMenu aria-label="Link 4" menuLinkName="Pricing">
          <HeaderMenuItem href="#">Pricing Overview</HeaderMenuItem>
          
        </HeaderMenu> */}
       <HeaderMenuItem href="#"  style={{fontWeight:'700', fontSize:'16px'}}><Link to="/" activeStyle={{ color: "white" }} style={{textDecoration:'none', color:'#fff'}}>Pricing</Link></HeaderMenuItem>
        
       <HeaderMenuItem href="#"  style={{fontWeight:'700', fontSize:'16px'}}><Link to="/" activeStyle={{ color: "white" }} style={{textDecoration:'none', color:'#fff'}}>Company</Link></HeaderMenuItem>

       <HeaderMenuItem href="#"  style={{fontWeight:'700', fontSize:'16px'}}><Link to="/" activeStyle={{ color: "white" }} style={{textDecoration:'none', color:'#fff'}}>Services</Link></HeaderMenuItem>

        <HeaderMenuItem href="#" style={{fontSize:'18px'}}>عربى</HeaderMenuItem>
        {/* <HeaderMenuItem href="#"><h3 style={{marginLeft:'20px', marginRight:'20px'}}>Commerce2x officially launches in UAE</h3></HeaderMenuItem> */}
        <HeaderMenuItem href="#"  style={{fontWeight:'700', fontSize:'16px', color:'#D0F227'}} onClick={this.openForm}>Get In Touch</HeaderMenuItem>
        <HeaderMenuItem href="#"  style={{fontWeight:'700', fontSize:'16px', color:'#D0F227'}}>Request a demo</HeaderMenuItem>
        <HeaderMenuItem href="#"  style={{fontWeight:'700', fontSize:'20px', color:'#fff'}}>Call us at +971 55 9419961</HeaderMenuItem>
      </HeaderNavigation>
      <HeaderGlobalBar>
      
     
      

       
      </HeaderGlobalBar>
      
    </Header>
    <div className="ExamplePopup">
        <ReactTypeformEmbed
          popup
          autoOpen={false}
          url="https://zulfiqaralisoomro.typeform.com/to/EDmhxc"
          hideHeaders
          hideFooter
          style={{ top: 80 }}
          ref={tf => {
            this.typeformEmbed = tf;
          }}
        />
       
      </div>
    {/* <div style={{backgroundColor:'#15141B', height:'60px', width:'100%', marginTop:'80px', color:'#fff', textAlign:'center', paddingTop:'4px', fontWeight:'300'}}>
    <div className="container" style={{textAlign:'right', marginRight:'50px'}}>
   
    </div>
    </div> */}
  </div>
    )
  }
}
