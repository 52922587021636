import React from "react"
import styled from "styled-components"
import logo from "../../images/product/commerce2x-logo.png";
import { Link } from "gatsby"

import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
   
      <FooterColumn>
        <span>Platform</span>
        <ul>
          <li><Link to="/platform" activeStyle={{ color: "white" }} style={{textDecoration:'none', color:'#fff'}}>Commerce Cloud</Link></li>
          <li>Commerce2x Enterprise</li>
         
          {/* <li>Tech</li>
          <li>Get Started</li> */}
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Pricing</span>
        <ul>
          <li>Pricing Overview</li>
        
          {/* <li>Tech</li>
          <li>Get Started</li> */}
        </ul>
      </FooterColumn>
      
      {/* <FooterColumn>
        <span>Features</span>
        <ul>
          <li>Compare</li>
          <li>Blog</li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Industry</span>
        <ul>
          <li>Auto</li>
          <li>Clothing</li>
          <li>Food</li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Resources</span>
        <ul>
          <li>Documentation</li>
          <li>Helpdesk</li>
        </ul>
      </FooterColumn> */}
      
      <FooterColumn>
        <span>Company</span>
        <ul>
          <li>About Us</li>
          <li>Advisory Board</li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Social</span>
        <ul>
          <li>LinkedIn</li>
          <li>Instagram</li>
        </ul>
      </FooterColumn>
      <Logo><img src={logo} style={{width:'250px'}}></img></Logo>
    </FooterColumnContainer>
    <BrandContainer>
      
     
   
      
      <FooterColumn>
        <span>Careers &nbsp;&nbsp;&nbsp;</span>
      </FooterColumn>
      
      <FooterColumn>
        <span>Terms of Service &nbsp;&nbsp;&nbsp;</span>
      </FooterColumn>
      <FooterColumn>
        <span>Privacy Policy &nbsp;&nbsp;&nbsp;</span>
      </FooterColumn>
      <FooterColumn>
        <span style={{fontWeight:400}}>Copyright © 2020 Commerce2x, Inc. All rights reserved. An <a href="http://www.innovationfloor.com" style={{color:'#0FC0C9', textDecoration:'none'}} target="_blank">Innovation Floor </a>Company</span>
      </FooterColumn>
      
    </BrandContainer>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: #15141B;
  padding: 0 0 80px;
  color: ${props => props.theme.color.white.regular};
`

const Logo = styled.div`
  font-family: ${props => props.theme.font.extrabold};
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.white.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${props => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  padding-top:80px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${props => props.theme.font.bold};
    color: ${props => props.theme.color.white};
    font-weight:600;
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.white.regular};
    li {
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.normal};
      font-size: 15px;
    }
  }
`

export default Footer
